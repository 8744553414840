@font-face {
  font-family: "mainFont";
  src: url("./assets/fonts/Prompt/Prompt-ExtraLight.ttf");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.input-label {
  width: 100%;
  color: #fff;
  font-weight: bolder;
}

.bt-search-filter {
  margin-bottom: 20px;
  color: white;
  width: 100%;
  background: #005baf;
  font-weight: bolder;
}

input,
select {
  font-size: 12px;
  padding: 0px 10px;
  background: white;
  border: none;
  border-radius: 4px;
  height: 30px;
  font-weight: bold;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);

  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline-style: none !important;
  border-color: transparent !important;
  z-index: 11;
}

button {
  font-size: 16px;
  padding: 0px 10px;
  color: black;
  background: #215cff;
  border: none;
  border-radius: 4px;
  height: 30px;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  outline-style: none !important;
  border-color: transparent !important;
}

.main-contrainer {
  font-family: "mainFont";
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  position: fixed;
  background: white;
  overflow: hidden;
}

.main-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.menu {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  font-size: calc(10px + 2vmin);
  color: white;
}

.noti {
  background-color: transparent;
  margin-right: 200px;
}

.App-link {
  color: #004d66;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-checkmark {
  display: flex;
  /* align-items: center; */
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bolder;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 10px;
  padding: 0;
}

/* Hide the browser's default checkbox */
.custom-checkmark>input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkmark>.label {
  flex-grow: 1;
}

/* Create a custom checkbox */
.checkmark {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 5px;
  min-width: 22px;
}

/* On mouse-over, add a grey background color */
.custom-checkmark:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkmark input:checked~.checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  /* position: absolute; */
  display: none;
}

/* Show the checkmark when checked */
.custom-checkmark input:checked~.checkmark:after {
  display: flex;
}

/* Style the checkmark/indicator */
.custom-checkmark .checkmark:after {
  /* left: 9px;
  top: 5px; */
  margin-top: 5px;
  margin-left: 9px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.display-control-contianer {
  display: none;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px;
  min-height: 50px;
  background: #fff;
  gap: 5px;
  flex-wrap: wrap;
}

.display-control-contianer>.contianer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.display-control-contianer>.contianer>.title {
  margin: 0px;
  padding: 5px 15px;
  background: #00000010;
  border-radius: 4px;
}

.display-control-contianer>.contianer>.checkbox {
  margin: 0px;
}

.display-control-contianer>.contianer.control-mobile {
  display: none !important;
}

.display-control-contianer>.contianer.control-mobile>button {
  color: #fff;
  font-weight: bolder;
}

/* //////////////////////// PAGE /////////////////////////////// */

.page-input {
  height: auto;
  min-height: 38px;
  margin-bottom: 10px;
  font-weight: bolder;
}

.page-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: black;
}

.page-content>.content {
  width: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 8;
  flex-grow: 1;
  padding: 0px;
  background: #eee;
}

.page-content>.content-mobile {
  flex-grow: 1;
  display: none;
  width: 100%;
}

.filter-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px;
  overflow: auto;
  background-image: url("./assets/images/main-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.filter-content>.legend-label-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bolder;
  background: #ffffff;
  padding: 8px;
  border-radius: 5px;
}

.filter-content>.legend-label-container>.head-label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bolder;
}

.filter-content>.legend-label-container>.legend {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  min-height: 20px;
}

.filter-content>.legend-label-container>.legend>.color {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 5px;
  margin-right: 10px;
}

.filter-content>.legend-label-container>.legend>.text {
  color: black;
}

.filter-content>.checkbox-display-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bolder;
  background: #ffffff;
  padding: 8px;
  border-radius: 5px;
  gap: 5px;
  margin-bottom: 10px;
}

.filter-content>.checkbox-display-container>.summary {
  padding-left: 32px;
  font-size: 30px;
}

.filter-content>.checkbox-display-container>.head-label {
  font-size: 16px;
  font-weight: bolder;
}

.filter-content>.checkbox-display-container>.checkbox>.icon {
  width: 30px;
}


.detail-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  background: #fff;
  gap: 10px;
  overflow: auto;
  overflow-x: hidden;
}

.detail-content>.selection-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -15px;
}

.detail-content>.selection-container>.head-label {
  font-weight: bolder;
}

.detail-content>.content-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: bolder;
}

.detail-content>.content-bar>.title {
  width: 100%;
  font-size: 18px;
  color: #af3700;
  position: relative;
  display: flex;
}

.detail-content>.content-bar>.lists {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detail-content>.content-bar>.lists>.list {
  width: 100%;
  min-height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: #00000010;
  gap: 10px;
  padding: 10px;
}

.detail-content>.content-bar>.lists>.list:hover {
  background-color: #005baf20;
}

.detail-content>.content-bar>.lists>.list>.t {
  max-width: 200px;
  min-width: 200px;
  font-size: 12px;
  line-break: anywhere;
}

.detail-content>.content-bar>.lists>.list>.h {
  font-size: 12px;
  min-width: 10px;
  max-width: 10px;
  text-align: center;
}

.detail-content>.content-bar>.lists>.list>.g {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
}

.detail-content>.content-bar>.lists>.list>.g>.hour {
  font-size: 10px;
  width: 100%;
}

.detail-content>.content-bar>.lists>.header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  color: #666;
  gap: 10px;
  padding: 10px;
}

.detail-content>.content-bar>.lists>.header>.t {
  max-width: 120px;
  min-width: 120px;

}


.detail-content>.content-bar>.lists>.header>.t>.bt-download {
  font-size: 12px;
  color: #FFF;
  font-weight: bolder;
  padding: 0px 20px;
}

.detail-content>.content-bar>.lists>.header>.h {
  font-size: 12px;
  min-width: 10px;
  max-width: 10px;
  text-align: center;
}

.detail-content>.content-bar>.lists>.header>.g {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.detail-content>.content-bar>.lists>.header>.g>.start-arrow {
  margin-right: -1px;
  font-size: 12px;
}

.detail-content>.content-bar>.lists>.header>.g>.line-arrow {
  flex-grow: 1;
  height: 2px;
  background: #666;
}

.detail-content>.content-bar>.lists>.header>.g>.arrow {
  margin-left: -1px;
  margin-top: -1px;
}

.detail-content>.content-bar>.lists>.header>.g>.label {
  /* margin-left: 10px; */
  font-size: 12px;
  font-weight: unset;
}

.detail-content>.content-bar>.footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  gap: 10px;
  /* background: #eee; */
  padding: 0px 10px;
  border-radius: 4px;
}

.detail-content>.content-bar>.footer>.remark {
  font-size: 12px;
}

.detail-content>.content-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  position: relative;
  overflow: auto;
  margin-right: -18px;
  gap: 5px;
}

.detail-content>.content-table>.head-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  /* height: 40px; */
  font-size: 12px;
  font-weight: bolder;
  color: black;
  border-radius: 4px;
  cursor: pointer;
  gap: 5px;
  position: sticky;
  width: 100%;
  top: 0;
}

.detail-content>.content-table>.head-table>.field {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 12px;
}

.detail-content>.content-table>.list-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 40px;
  font-size: 12px;
  font-weight: bolder;
  color: black;
  border-radius: 4px;
  cursor: pointer;
  background-color: #00000010;
  gap: 5px;
}

.detail-content>.content-table>.list-table:hover {
  background-color: #005baf20;
}

.detail-content>.content-table>.list-table>.field {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .display-control-contianer {
    font-size: 12px;
    justify-content: center;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 700px) {
  .display-control-contianer {
    display: flex;
    font-size: 12px;
    justify-content: center;
  }

  .display-control-contianer>.contianer {
    gap: 10px;
    flex-wrap: wrap;
  }

  .display-control-contianer>.contianer>.title {
    /* width: 100%; */
    flex-grow: 1;
  }

  .display-control-contianer>.contianer>.checkbox {
    font-size: 12px;
  }

  .display-control-contianer>.contianer.control-mobile {
    display: flex !important;
  }

  .page-content>.content {
    position: absolute !important;
    width: 100%;
  }

  .page-content>.content-mobile {
    display: block;
  }
}

.toggle-web{
    display: flex!important;
}

.toggle-mobile{
    display: none!important;
}


.content-report{
    width: 100%;
    height: auto;
    flex-grow: 1;
    flex-basis: 0px;
    position: relative;
    overflow: auto;
}

.content-report > .table-report{
    width: 100%;
    max-width: 100%;
    height: fit-content;
    background: white;
}

.content-report > .table-report > thead,tbody > tr,th,td{
    font-size: 12px;
    text-align: center;
    font-weight: bolder;
    padding: 0px;
    background: #FFF;
    border-collapse: collapse;
    /* border: none; */
}

.bg-cell-table-report{
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: white;
    border: 1px solid rgb(56, 56, 56);
    margin: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

@media screen and (max-width: 998px) {
    /* .setting-page{
        padding-right: 40px !important;
    } */
}

@media screen and (max-width: 700px) {
    .toggle-web{
        display: none!important;
    }
    .toggle-mobile{
        display: flex!important;
    }
    /* .setting-page{
        padding-right: unset !important;
    } */
}

@media screen and (max-width: 450px) {

    .title-report{
        flex-basis: 0!important;
        min-width: 150px!important;
    }
    /* .setting-page{
        padding-right: unset !important;
    } */
}
